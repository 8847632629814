import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import checkAuth from "./system/auth/authenticate.js";
Vue.use(Router);

// create new router

const routes = [
  {
    path: "/",
    component: () => import("./views"), 
    redirect: "/homepage",
    beforeEnter: checkAuth,
    children: [
      {
        path: "/homepage",
        component: () => import("./views/home/homePage"),
      },
      {
        path: "/profit",
        component: () => import("./views/profit/profit"),
      },
      {
        path: "/main",
        component: () => import("./views/main"),
      },
      {
        path: "/nature",
        component: () => import("./views/nature/nature"),
      },
      {
        path: "/mePage",
        component: () => import("./views/settings/mePage"),
      },

    ]
  },
  {
    path: "/mainRecord",
    component: () => import("./views/mainRecord"),
  },
  {
    path: "/newsList",
    component: () => import("./views/settings/news/newsList")
  },
  
  {
    path: "/newsDetails",
    name: "newDetails",
    props: true,
    component: () => import("./views/settings/news/newsDetails"),
  },
  {
    path: "/tutorial",
    component: () => import("./views/sessions/firstTime")
  },
  {
    path: "/metamask",
    component: () => import("./views/metamask")
  },
  {
    path: "/setting",
    beforeEnter: checkAuth,  
    component: () => import("./views/settings/setting")
  },
  {
    path: "/help",
    beforeEnter: checkAuth,  
    component: () => import("./views/settings/help")
  },
  {
    path: "/sales",
    beforeEnter: checkAuth,  
    component: () => import("./views/profit/sales")
  },
  {
    path: "/memberTree",
    beforeEnter: checkAuth,  
    component: () => import("./views/profit/memberTree")
  },
  {
    path: "/performance",
    beforeEnter: checkAuth,  
    component: () => import("./views/profit/performance")
  },
  {
    path: "/team",
    beforeEnter: checkAuth,  
    component: () => import("./views/settings/team")
  },
  {
    path: "/USDTwithdraw",
    beforeEnter: checkAuth,  
    component: () => import("./views/withdraw/USDTwithdraw")
  },
  {
    path: "/withdraw",
    beforeEnter: checkAuth,  
    component: () => import("./views/withdraw/withdraw")
  },
  {
    path: "/deposit",
    beforeEnter: checkAuth,  
    component: () => import("./views/settings/deposit")
  },
  {
    path: "/depositProgress",
    beforeEnter: checkAuth,  
    component: () => import("./views/settings/depositProgress")
  },
  {
    path: "/transfer",
    beforeEnter: checkAuth,  
    component: () => import("./views/withdraw/transfer")
  },
  {
    path: "/record",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/record")
  },
  {
    path: "/record",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/record")
  },
  {
    path: "/invite",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/invitePage")
  },
  {
    path: "/login",
    component: () => import("./views/sessions/loginPage")
  },
  {
    path: "*",
    component: () => import("./views/pages/notFound")
  }
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.

    NProgress.start();
    NProgress.set(0.1);
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  setTimeout(() => NProgress.done(), 100);
});

export default router;
