import { defineStore } from "pinia";
import { getAbi, getERC20_Abi, getUSDTABI, piAbi } from './abi'
import { ethers } from "ethers";
// import keccak256 from "keccak256";
// import { MerkleTree } from "merkletreejs";

export const useMetatool = defineStore("metatool", () => {

    var company_address = location.origin.includes('app') ? '0xAa3755edBB19Db8209eAEea069D4d57b8f6f9ed1' : "0xf6e07D8F454853c39412aEB75F689E4337F9f481";
    const factoryContractAddress = '0x1aA8f4dDBD857390C6961073a09722e8A6C1Ef64';
    // var factoryContractAddress = '0x8e2705424e3cbde461b26a486614a7a162321ee4';


    var errors = {};
    var isInit = false;
    var metamaskEnabled = false;
    var currentAccount = '';
    var currentChainId = '';
    var chainIds = [56];
    var chainIdsData = {
        
        56: {
            chainId: '0x38',
            chainName: 'BNB Smart Chain Mainnet',
            chainNameShort: 'Binance',
            type: 'M',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            },
            rpcUrls: ['https://bsc.publicnode.com/'],
            blockExplorerUrls: ['https://bscscan.com']
        },
        // 97: {
        //     chainId: '0x61',
        //     chainName: 'BNB Smart Chain Testnet',
        //     chainNameShort: 'Binance',
        //     type: 'T',
        //     nativeCurrency: {
        //         name: 'BNB',
        //         symbol: 'BNB',
        //         decimals: 18
        //     },
        //     rpcUrls: ['https://endpoints.omniatech.io/v1/bsc/testnet/public'],
        //     blockExplorerUrls: ['https://bscscan.com']
        // },

    }
    async function getCurrentAccountFromMetamask() {
        return await window.ethereum.request({ method: 'eth_accounts' });
    }
    async function getCurrentChainIdFromMetamask() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const chainId = await signer.getChainId();
        return chainId
    }
    async function getCurrentChainNetworkFromMetamask() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const chainId = await signer.getChainId();
        let chainNetwork;
        for (const [key, value] of Object.entries(chainIdsData)) {
            if (key == chainId) {
                chainNetwork = JSON.stringify(value);
            }
        }
        return chainNetwork
    }
    async function requestAccountFromMetamask() {
        try {
            return await window.ethereum.request({ method: 'eth_requestAccounts' });
        } catch (error) {
            errors = {
                requestAccount: error
            }
            return ''
        }
    }
    function checkIsMetamaskEnabled() {
        return typeof window.ethereum !== 'undefined'
    }
    async function connectWallet() {
        if (checkIsMetamaskEnabled()) {
            metamaskEnabled = true;
            let rs = await getCurrentAccountFromMetamask()

            if (rs.length == 0) {
                rs = await requestAccountFromMetamask()
            }
            console.log('rs', rs);
            currentAccount = rs[0] || '';

            currentChainId = await getCurrentChainIdFromMetamask();

            console.log(currentChainId);

            if (!chainIds.includes(currentChainId)) {
                await switchChainId(56);
                await switchChainId(97);

            }


            return rs;
        } else {
            if (window.outerWidth < 480) {
                alert("Please install Metamask and use it's internal browser");

            } else {
                window.open('https://metamask.io/download/', '__blank');

            }
        }
    }
    async function switchChainId(id) {
        try {
            var tmp = {}
            tmp['chainId'] = chainIdsData[id].chainId;
            tmp['chainName'] = chainIdsData[id].chainName;
            tmp['rpcUrls'] = chainIdsData[id].rpcUrls;
            tmp['nativeCurrency'] = chainIdsData[id].nativeCurrency;
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    tmp
                ],
            });
            return true
        } catch (error2) {
            console.log(error2)
            errors = {
                addEthereumChain: error2
            }
            return false
        }
    }

    async function getBalanceFromMetamask() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        let balance = await provider.getBalance(currentAccount)
        balance = ethers.utils.formatEther(balance)
        // console.log(`balance: ${balance} ETH`);
        return balance;
    }

    async function checkContract(contractAddress) {
        if (checkIsMetamaskEnabled()) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const myContract = new ethers.Contract(contractAddress, getAbi(), signer);
            const name = await myContract.name();
            const symbol = await myContract.symbol();
            const baseSettings = await myContract.baseSettings();
            const baseURI = await myContract._baseTokenURI();
            const isPublicActive = await myContract.isPublicActive();

            return {
                name,
                symbol,
                baseSettings,
                isPublicActive,
                baseURI
            }
        }
        return null
    }

    async function getSignature(message) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();
        const signature = await signer.signMessage(message);

        return signature

    }

    // async function checkAndSetAllowance(wallet, tokenAddress, approvalAddress, amount) {
    //     // Transactions with the native token don't need approval
    //     if (tokenAddress === ethers.constants.AddressZero) {
    //         return
    //     }

    //     const erc20 = new ethers.Contract(tokenAddress, getERC20_Abi(), wallet);
    //     const allowance = await erc20.allowance(await wallet.getAddress(), approvalAddress);
    //     if (allowance.lt(amount)) {
    //         const approveTx = await erc20.approve(approvalAddress, amount, { gasPrice: await wallet.provider.getGasPrice() });
    //         // try {
    //         //     await approveTx.wait();
    //         //     console.log(`Transaction mined succesfully: ${approveTx.hash}`)
    //         // }
    //         // catch (error) {
    //         //     console.log(`Transaction failed with error: ${error}`)
    //         // }
    //         return approveTx;
    //     } else {
    //         return true;
    //     }
    // }

    async function depositToken(amount) {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();
        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);
        if ('0x3702117BB800dee5b833f72180C4310392Cdd6b9' === ethers.constants.AddressZero) {
            return
        }

        const erc20 = new ethers.Contract('0x3702117BB800dee5b833f72180C4310392Cdd6b9', getERC20_Abi(), signer);
        const allowance = await erc20.allowance(await signer.getAddress(), factoryContractAddress);
        if (allowance.lt(amount)) {
            const approveTx = await erc20.approve(factoryContractAddress, amount, { gasPrice: await signer.provider.getGasPrice() });

            try {
                await approveTx.wait();
                let res = await myContract.depositToken(amount);

                return {
                    res: res,
                    wait: res.wait()
                }
            } catch (err) {
                console.log(err);
                return { error: err.error };

            }
        } else {
            try {
                let res = await myContract.depositToken(amount);

                return {
                    res: res,
                    wait: res.wait()
                }
            } catch (err) {
                // console.log(err);
                return { error: err.error };

            }
        }

    }
    async function deposit(pid, amount) {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();
        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        // let approveTx = await checkAndSetAllowance(
        //     signer,
        //     '0x0884D76e143Afa6D33E224c8CC17a81e169c8a8C', // fromTokenAddress 
        //     factoryContractAddress, // addressToApprove 
        //     ethers.constants.MaxUint256 // amount to approve (infinite approval)
        // );
        if ('0x55d398326f99059fF775485246999027B3197955' === ethers.constants.AddressZero) {
            return
        }

        const erc20 = new ethers.Contract('0x55d398326f99059fF775485246999027B3197955', getERC20_Abi(), signer);
        const allowance = await erc20.allowance(await signer.getAddress(), factoryContractAddress);
        if (allowance.lt(amount)) {
            const approveTx = await erc20.approve(factoryContractAddress, amount, { gasPrice: await signer.provider.getGasPrice() });

            try {
                await approveTx.wait();
                let res = await myContract.deposit(pid);

                return {
                    res: res,
                    wait: res.wait()
                }
            } catch (err) {
                console.log(err);
                return { error: err.error };

            }
        } else {
            try {
                let res = await myContract.deposit(pid);

                return {
                    res: res,
                    wait: res.wait()
                }
            } catch (err) {
                // console.log(err);
                return { error: err.error };

            }
        }

    }

    async function usdtWithdraw() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract('0x87AEdc3f07afB5a2CCf030172f2BB25595b0E21e', getUSDTABI(), signer);
        // const myContract = new ethers.Contract('0x498fE3075E7dba58fc8799239f55bAEe88A89984', getUSDTABI(), signer);

        let res;

        try {
            res = await myContract.withdrawIncome();
            return {
                contract: res,
                wait: res.wait()
            }
        } catch (err) {
            console.log(err);
            return { error: err.error };

        }
    }

    async function members() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        // const myContract = new ethers.Contract('0x87AEdc3f07afB5a2CCf030172f2BB25595b0E21e', getUSDTABI(), signer);
        const myContract = new ethers.Contract('0x498fE3075E7dba58fc8799239f55bAEe88A89984', getUSDTABI(), signer);

        let res;

        try {
            res = await myContract.members(signer.getAddress());
        } catch (error) {
            console.log(error)
        }

        return res;
    }

    async function withdraw(signature, amount, orderId, deadline) {

        console.log(signature);
        console.log(amount);
        console.log(orderId);
        console.log(deadline);
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        let res = await myContract.withdraw(signature, amount, orderId, deadline);

        return {
            contract: res,
            wait: res.wait()
        }
    }

    async function getInviter() {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        let address = await myContract.getInviter(currentAccount);

        console.log(address)

        return /^0x0+$/.test(address);
    }

    async function getPoolInfos() {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        let res = await myContract.getPoolInfos();

        return res;
    }

    async function bindInviter(address) {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        let res;


        res = await myContract.bindInviter(address);

        return {
            res: res,
            wait: res.wait()
        }
    }

    async function getBalance(address) {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract('0x00795cd423D29dE3007192dF770fC0e0C8b3Ad33', piAbi(), signer);

        let res = await myContract.balanceOf(address);

        return ethers.utils.formatEther(res);
    }

    async function getINFBalance(address) {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract('0x3702117BB800dee5b833f72180C4310392Cdd6b9', piAbi(), signer);

        let res = await myContract.balanceOf(address);

        return ethers.utils.formatEther(res);
    }

    metamaskEnabled = checkIsMetamaskEnabled();
    if (metamaskEnabled) {
        getCurrentAccountFromMetamask().then(acc => {
            currentAccount = acc[0]
        });
        getCurrentChainIdFromMetamask().then(chainId => {
            currentChainId = chainId
        })

        window.ethereum.on('accountsChanged', async (accounts) => {
            if (accounts.length === 0) {
                currentAccount = ''
                console.log('Please connect to MetaMask.');
                localStorage.removeItem('infinityToken');
                window.location.reload();
            } else if (accounts[0] !== currentAccount) {
                if (currentAccount) {
                    localStorage.removeItem('infinityToken');
                    window.location.reload();

                }
                currentAccount = accounts[0]
                console.log('accountsChanged', accounts);
            }
        });

        window.ethereum.on('chainChanged', (chainId) => {
            currentChainId = ethers.BigNumber.from(chainId).toNumber()
            console.log('chainChanged', ethers.BigNumber.from(chainId).toNumber(), chainId);
            localStorage.removeItem('infinityToken');
            window.location.reload();
        });
        isInit = true;
    } else {
        currentAccount = ''
        console.log('Please install MetaMask.');
    }

    return {
        errors,
        metamaskEnabled,
        currentAccount,
        currentChainId,
        chainIds,
        // chainIdsTestNet, chainIdsMainnet,
        chainIdsData,
        isInit,
        company_address,
        getBalance,
        getBalanceFromMetamask,
        getCurrentAccountFromMetamask,
        checkIsMetamaskEnabled,
        connectWallet,
        switchChainId,
        checkContract,
        getCurrentChainNetworkFromMetamask,
        getCurrentChainIdFromMetamask,
        deposit,
        depositToken,
        withdraw,
        usdtWithdraw,
        getSignature,
        getInviter,
        getPoolInfos,
        bindInviter, getINFBalance,
        members
    }
})