import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/styles/sass/global.scss";
import i18n from "./system/lang/lang.js";
import { createPinia } from "pinia";
import VueQRCodeComponent from 'vue-qrcode-component';
import { noticeList } from '@/system/request/api/api';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.component('qr-code', VueQRCodeComponent);

Vue.use(VueGoodTablePlugin);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(createPinia())

Vue.config.productionTip = false

new Vue({
  data() {
    return {
      isLoading: false,
      userInfo: {},
      teamList: [],
      currentAddress: '',

      newsList: [],
      pageParams: {
        page: 0,
        language: '',
        size: 5,
      },
    }
  },
  methods: {
    goNewsDetails(item) {
      this.$router.push({
        name: 'newDetails',
        params: {
          item: item
        }
      });
    },
    getNews() {
      var self = this;
      let params = '?';
      for (const key in self.pageParams) {

        if (key == 'language') {
          params = `${params}${key}=${this.$i18n.locale}`;

        } else {
          params = `${params}${key}=${self.pageParams[key]}`;
        }
        if (key != 'size') {
          params = params + '&';

        }
      }
      this.$root.isLoading = true;
      var result = noticeList(params);
      result
        .then(function (value) {
          var dataList = value.data.data.content;
          self.pageParams.page += 1;
          self.last = value.data.data.last;
          for (let i = 0; i < dataList.length; i++) {
            self.newsList.push(dataList[i]);
          }
          self.$root.isLoading = false;
          if (self.$root.newsList.length > 0) {
            self.$bvModal.show('modal-countdown');
          }
        })
        .catch(function () {
          self.$root.isLoading = false;
          // self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },
    changeLan(lan) {
      localStorage.setItem('lan', lan);
      this.$i18n.locale = lan;
    },
    makeToast(variant = null, msg, type) {
      this.msg = msg;
      this.variant = variant;
      this.type = type;
      this.$bvToast.toast(msg, {
        // // title: ` ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
