import request from '../request';
import config from '../config';

var current = config.url;
// var current = location.origin + '/';
export var tokenInfo = {
	//testnet
	// 'token': '0x1dc6723689baCc114A3698b695d6D8E6c2c66182',
	// 'pool': '0xdbbd9fDb6ae7F39D64163735e25dA9270c9F30FD'
	'token': '0x00795cd423D29dE3007192dF770fC0e0C8b3Ad33',
	'pool': '0xa7752eC01eCc021E12781c6A17c8b0a938e69AA6'
}

function retHeader() {
	const header = {
		'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
		'token': localStorage.getItem("infinityToken"),
	};
	return header;

}

export function queryTokenPrice() {
	const data = request({
		url: current + 'api/v1/token/queryTokenPrice?token=' + tokenInfo['token'] + '&pool=' + tokenInfo['pool'],
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function destroyPrice() {
	const data = request({
		url: current + 'api/v1/token/queryTokenPrice?token=' + '0x0000000000000000000000000000000000000000' + '&pool=' + tokenInfo['pool'],
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function fetchJson() {
	const data = request({
		url: current + 'file/tx.json',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function unlockedAmount() {
	const data = request({
		url: current + 'api/v1/token/unlockedAmount',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function noticeList(params) {
	const data = request({
		url: current + 'api/v1/sys/noticeList'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getUserInfo() {
	const data = request({
		url: current + 'api/v1/user/info',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function teamByAddress(address) {
	const data = request({
		url: current + `api/v1/user/teamByAddress?address=${address}`,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getTeamList(uid) {
	const data = request({
		url: current + `api/v1/user/team?uid=${uid}`,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getAwardList(params) {
	const data = request({
		url: current + `api/v1/award/list${params}`,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getDrawList(params) {
	const data = request({
		url: current + `api/v1/draw/list${params}`,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getTokenAmounts(params) {
	const data = request({
		url: (current + `api/v1/token/tokenAmount${params}&token=` + tokenInfo['token']),
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getAITokenAmounts(params) {
	const data = request({
		url: (current + `api/v1/token/tokenAmount${params}&token=` + '0x55d398326f99059fF775485246999027B3197955'),
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getTokenPrice(params) {
	const data = request({
		url: (current + `api/v1/token/price${params}&token=` + tokenInfo['token']),
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function setName(postData) {
	const data = request({
		url: current + 'api/v1/user/setName?name='+postData,
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function requestWithdraw(postData) {
	const data = request({
		url: current + 'api/v1/draw/withdraw',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function confirmWithdraw(id) {
	const data = request({
		url: current + `api/v1/draw/confirm/${id}`,
		method: 'post',
		// data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function authMessage(postData) {
	const data = request({
		url: current + 'api/v1/auth/authMessage',
		method: 'post',
		data: postData,
		// headers: retHeader()
	});

	return Promise.resolve(data);
}

export function systemInfo() {
	const data = request({
		url: current + 'api/v1/sys/info',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function authLogin(postData) {
	const data = request({
		url: current + 'api/v1/auth/authLogin',
		method: 'post',
		data: postData,
		// headers: retHeader()
	});

	return Promise.resolve(data);
}

