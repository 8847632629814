<template>
  <div id="app" :style="{ 'width': computedWidth }">
    <div class="gradient-bg">
      <div class="blur-1"></div>
      <div class="blur-2"></div>
      <div class="blur-3"></div>
    </div>
    <div v-if="$root.isLoading" class="loading">
      <div class="loading-box">
        <div class="loading-effect">
          <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>

        </div>

      </div>


    </div>
    <transition>
      <router-view></router-view>
    </transition><b-modal id="modal-countdown" centered hide-footer hide-header>

      <div class="full-screen">
        <carousel :items="1" :nav="false" v-if="$root.newsList.length > 0">
          <img v-for="item in $root.newsList" :key="item.id" class="rounded" :src="decodeURIComponent(apiLink + item.url)"
            width="100%" @click="goNewsDetails(item)">

        </carousel>
      </div>
      <div class="float-box" @click="$bvModal.hide('modal-countdown')">
        <div class="round-box icon-button ml-1" style="padding: 4px 10px;">
          x
        </div>

      </div>
    </b-modal>

  </div>
</template>

<script>
import config from '@/system/request/config';
import carousel from 'vue-owl-carousel'
export default {
  name: 'Infinity X',
  data() {
    return {
      success: require('./assets/images/login/success.png'),
      danger: require('./assets/images/login/error.png'),
      screenWidth: 0,
      apiLink: config.url,


    }
  },
  components: {
    carousel,
  },
  watch: {
    '$parent.msg': 'checkType'
  },
  computed: {
    computedWidth: function () {
      return this.screenWidth + 'px';
    }
  },
  methods: {
    viewCart() {
      this.$bvModal.hide('modal-message');
      this.$router.push('/web/product/cartList');
    },
    viewRestock() {
      this.$bvModal.hide('modal-message');
      this.$router.push('/web/reseller/prompt');
    },
    checkType() {
      console.log(this.$parent.type);
      switch (this.$parent.type) {
        case 'reminder':
          this.img = this.reminder;
          break;
        case 'cart':
          this.img = this.success;
          break;
        case 'success':
          this.img = this.success;
          break;
        case 'save':
          this.img = this.save;
          break;
        case 'sent':
          this.img = this.sent;
          break;
        case 'invest':
          this.img = this.invest;
          break;
        case 'surrender':
          this.img = this.surrender;
          break;
        case 'danger':
          this.img = this.danger;
          break;
        case 'coming':
          this.img = this.coming;
          break;

        default:
          this.img = this.success;
          break;
      }
    }
  },
  mounted() {
    // console.log('version 1.0.0')
    this.screenWidth = screen.width;
    // this.$root.getNews();

  }
}
</script>

<style>
#app {
  font-family: 'Poppins-Medium' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
